@import "../../../App.scss";

.pageBanner {
  width: 100%;
  height: auto;
  background: $dark;
  color: $light;
  margin-top: 5vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;

  @media (max-width: 767px) {
    grid-template-columns: auto;
  }

  & .workBanner,
  .aboutBanner {
    overflow: hidden;
    width: 100%;
    height: 50vh;
    min-height: 250px;
    position: relative;
    @include centeritem;

    @media (min-width: 768px) and (max-width: 1023px) {
      height: 30vh;
    }

    @media (max-width: 767px) {
      height: auto;
      padding: 1em;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transform: scale(1.1);
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: 0.8s var(--transition);
    }

    .text__frame {
      @media (max-width: 1023px) {
        width: 50%;
      }
      .heading-4 {
        font-weight: 600;
      }
      & .body-large {
        font-weight: 300;
        margin: 0.5em 0 2em 0 !important;
        line-height: 1.3;
      }

      & .hlink {
        color: $light !important;
        border-bottom: 2px solid darken($light, 70%) !important;

        &::after {
          background: $light !important;
        }
      }
    }

    &:hover {
      cursor: pointer;
      img {
        opacity: 0.12;
        transform: scale(1);
      }
    }
  }
}
