@import "../../App.scss";

.hlink {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  position: relative;
  text-decoration: none;
  padding: 0.25em 0;
  color: $dark !important;
  border-bottom: 2px solid $dark--2;
  font-size: $subheading;
  font-weight: 600;

  & svg {
    font-size: inherit;
    font-weight: inherit;
    height: 100%;
    color: inherit !important;
    margin-left: 0.5em;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: $dark;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
  }

  &:hover {
    text-decoration: none;
    color: inherit;

    &:after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
}
