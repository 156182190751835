.sub__btn {
  background: transparent;
  border: transparent;
  padding: 0.5em 0;
  border-radius: 0;
  color: $dark;
  text-transform: capitalize;
  font-size: $subheading;
  font-weight: 600;
  transition: 0.5s var(--transition);
  border-bottom: 2px solid $dark--2;

  &:hover {
    border-bottom: 2px solid $dark;
  }
}

.bbtn {
  background: $dark;
  color: $light;
  text-decoration: none;
  padding: 0.6em 1.75em;
  border-radius: var(--border_low);
  font-size: $subheading;
  font-weight: 500;
  display: inline-block;
  transition: 0.5s var(--transition);

  &:hover {
    text-decoration: none;
    color: $light;
    background: darken($prim, 30%);
  }
}
