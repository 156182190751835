@import "../../App.scss";

footer {
  padding: 10vh 0;
  background: $prim__light;
  border-top: 1px solid lighten($prim, 40%);
  position: relative;
  z-index: 999;

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  .footer__wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 1em;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
      gap: 5vh;
    }

    .footer__context {
      .heading-3 {
        font-weight: 700;
        letter-spacing: -1px;
      }
      .hlink {
        font-size: $heading-5;
        font-weight: 500;
      }
    }

    .footer__social {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1em 2em;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
