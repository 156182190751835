@import "../../../App.scss";

.home__banner {
  padding: 0;
  margin-bottom: 5vh;
  width: 100%;
  height: auto;
  min-height: 80vh;
  background: linear-gradient(
    90deg,
    rgba(90, 33, 232, 0) 0%,
    rgba(90, 33, 232, 0) 60%,
    rgba(90, 33, 232, 1) 60%,
    rgba(90, 33, 232, 1) 100%
  );

  @media (max-width: 767px) {
    background: none;
    margin: 0;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    min-height: unset;
  }

  .container {
    min-height: inherit;
  }

  .banner__wrapper {
    width: 100%;
    height: 100%;
    min-height: inherit;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;

    @media (max-width: 767px) {
      grid-template-columns: auto;
    }

    .banner__textarea {
      width: 100%;
      height: 100%;
      padding-top: 100px;
      @include centeritem;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1em;

      @media (max-width: 767px) {
        padding: 0 0 2em 0;
      }

      .Typewriter {
        font-family: "Montserrat", sans-serif !important;
        font-weight: 500;
        font-size: $subheading;
        text-transform: uppercase;
      }

      .heading-2 {
        line-height: 1.15;
        width: 85%;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      .hlink {
        color: $prim !important;
        border-color: $prim__light !important;
        margin-top: 1em;

        & ion-icon {
          stroke: $prim;
        }

        &::after {
          background-color: $prim;
        }
      }
    }

    .banner__imagearea {
      overflow: hidden;
      padding-top: 100px;
      background: $prim;
      border-radius: 0 0 0 var(--border);
      position: relative;
      padding-bottom: 0;
      @include centeritem;
      align-items: flex-end;
      justify-content: flex-end;

      @media (max-width: 767px) {
        padding-top: 0;
        border-radius: var(--border);
        justify-content: center;
      }

      & .hero__img {
        width: 75%;
        z-index: 5;

        @media (max-width: 767px) {
          width: 60%;
          padding: 1em 0.1em 0 0.1em;
        }
      }

      & .brand__img {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 2%;
        transform: translate(-50%, -50%);

        @media (max-width: 767px) {
          top: 20%;
          left: 70%;
          width: 100%;
        }
      }
    }
  }
}
