@import "../../../App.scss";

.aboutInfo {
  .abtStory {
    padding: 10vh 0 0 0;

    @media (max-width: 767px) {
      padding: 5vh 25px 0 25px !important;
    }

    .subheading {
      font-weight: 400;
      line-height: 1.7;

      @media (max-width: 767px) {
        margin-top: 2em;
      }
    }
  }
}
