@import "../../../App.scss";

.abtApproach {
  width: 100%;
  background: $prim__light;

  .section__title {
    width: 40%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .approachList {
    margin-top: 6vh;
    padding-inline-start: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4em;

    @media (max-width: 767px) {
      grid-template-columns: auto;
      gap: 7vh;
    }

    li {
      .imgBox {
        background-color: $prim__light;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center left;
        background-blend-mode: multiply;
        height: 12vh;
        min-height: 100px;
        margin-bottom: 1em;
      }

      .heading-3 {
        font-weight: 600;
        position: relative;

        @media (max-width: 767px) {
          font-size: 22px;
        }

        &::before {
          content: "0" counter(list-item);
          font-size: $subheading;
          font-weight: 500;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: -1.2em;
          transform: translate(-50%, -50%);

          @media (max-width: 767px) {
            position: unset;
            transform: translate(0);
            display: block;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0.5em;
            letter-spacing: 1px;
          }
        }
      }

      .body-large {
        width: 80%;
        line-height: 1.75;
        margin-top: 1em;

        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
}
