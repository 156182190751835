@import "../../App.scss";

.projectBox {
  width: 100%;
  height: auto;
  transition-delay: 0.5s;

  &:hover {
    cursor: pointer;

    .project__image img {
      transform: scale(1.1);
    }

    .project__title .subheading {
      color: $prim;

      & span {
        color: $dark;
      }
    }
  }

  &:nth-child(even) {
    margin-top: -3em !important;
    transition-delay: 0.3s;
  }

  .project__image {
    width: 100%;
    height: 70vh;
    min-height: 200px;
    overflow: hidden;
    border-radius: var(--border_mid);
    background: $prim__light;

    @media (max-width: 767px) {
      height: 20vh;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      min-height: 300px;
      height: 40vh;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.8s cubic-bezier(0.75, 0.25, 0.25, 0.75);
    }
  }

  .project__title {
    padding: 1.5em 2em;
    text-align: center;

    @media (max-width: 767px) {
      padding: 1em 0.5em;
      text-align: left;

      & .subheading {
        font-size: 14px;
      }
    }

    .subheading {
      font-weight: 700;
      line-height: 1.6;
      transition: 0.5s var(--transition);

      & span {
        font-weight: 400;

        &::before {
          content: "—";
          color: $dark--2;
          display: inline-block;
          margin: 0 0.75em;
        }
      }
    }
  }
}
