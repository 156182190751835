@import "../../App.scss";

.projects {
  min-height: calc(100vh - 250px);
  .headerTags {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;

    @media (max-width: 767px) {
      grid-template-columns: auto;
    }
  }

  .loginContainer {
    .errorMsg {
      color: #ef233c;
      font-size: $caption-1;
      font-weight: 600;
    }
  }

  .jprojList {
    padding: 0;
    list-style-type: none;

    & li {
      background: $prim__light;
      border: 1px solid lighten($prim, 35%);
      border-radius: var(--border_mid);
      padding: 2em;
      margin-bottom: 1em;

      & .heading-4 {
        font-weight: 700;
        & span {
          color: $prim;
        }
      }

      & .href {
        font-size: $heading-6;
      }

      .dropdown-divider {
        border-top: 1px solid lighten($prim, 35%);
      }

      & .heading-6 {
        color: $prim;
      }
    }
  }
}
