@import "../../../App.scss";

.homeProcess {
  width: 100%;
  height: auto;
  padding: 0;
  background: $prim__light;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 1em;

  @media (max-width: 767px) {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: auto;
  }

  &__background {
    width: 100%;
    height: 100%;
    overflow: none;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    width: 100%;
    @include centeritem;
    padding: 10vh 2em;

    @media (max-width: 767px) {
      padding: 4em 0;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    & .homeProcess__list {
      list-style-type: none;
      margin: 2em 0;
      padding-inline-start: 1ch;

      @media (max-width: 767px) {
        padding-inline-start: 0;
      }

      & li {
        padding-left: 0.5em;

        @media (max-width: 767px) {
          padding-left: 0;
        }

        &:not(:last-child) {
          margin-bottom: 1em;
        }

        & .heading-3 {
          font-weight: 500;
          position: relative;

          @media (max-width: 767px) {
            font-size: 22px;
            font-weight: 600;
          }

          &::before {
            content: "0" counter(list-item);
            font-size: $subheading;
            font-weight: 500;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: -1.2em;
            transform: translate(-50%, -50%);

            @media (max-width: 767px) {
              position: unset;
              transform: translate(0);
              display: block;
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 0.5em;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
}
