@import "../../App.scss";

.alert-jpSuccess {
  background: $prim__light;
  border: 1px solid lighten($prim, 35%) !important;
  font-size: $heading-5;
  padding: 1em !important;
  text-align: center;
  color: $prim !important;
  font-weight: 600;

  h2 {
    margin-top: 1em;
    color: $prim;
  }
}
