@import "../../../../App.scss";

.abtBanner {
  width: 100%;
  height: 50vh;
  min-height: 200px;
  background: linear-gradient(
    180deg,
    rgba(90, 33, 232, 0) 0%,
    rgba(90, 33, 232, 0) 20%,
    rgba(90, 33, 232, 1) 20%,
    rgba(90, 33, 232, 1) 100%
  );
  position: relative;

  @media (max-width: 767px) {
    height: 10vh;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    height: 30vh;
  }

  .abtBackground {
    position: absolute;
    top: 20%;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;

    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .leftImg {
      width: 100%;
      height: 100%;
      position: relative;
      @include centeritem;

      & img {
        margin-top: 20%;
        width: 35%;
        min-width: 50px;
        height: 40%;

        @media (max-width: 767px) {
          width: 20%;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
          margin-top: 20%;
          width: 30%;
        }
      }
    }

    .rightImg {
      grid-column-start: 3;
      grid-column-end: 4;
      width: 100%;
      height: 100%;
      position: relative;

      @media (max-width: 767px) {
        grid-column-start: 2;
        grid-column-end: 3;
      }

      & img {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }
}
