@import "./sass/variables";
@import "./sass/responsive";
@import "./sass/textstyles";
@import "./sass/buttons";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  color: $dark !important;
  width: 100%;
  margin: 0px;
  padding: 0;
  height: auto;
  position: relative;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif !important;
}

section {
  padding: 10vh 0;

  @media (max-width: 1023px) {
    padding: 4em 0;
  }
}

h2 {
  margin-bottom: 0 !important;
}

.section__title {
  font-size: $heading-3;
  letter-spacing: -1px;
  font-weight: 700;

  // @media (max-width: 767px) {
  //   font-size: 21px;
  // }
}

.section__subtitle {
  font-size: $body-large;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.5;
  margin-bottom: 0.25em !important;

  // @media (max-width: 767px) {
  //   font-size: 11px;
  // }
}

::selection {
  background-color: $prim__light;
  color: $prim;
}

.firstDiv {
  padding-top: calc(100px + 2em);

  @media (max-width: 767px) {
    padding: 0 0 5vh 0;
  }
}

.work__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  margin-top: 2em;
}

.form-control {
  width: 100%;
  height: auto !important;
  padding: 0.5em !important;
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid $dark--2 !important;
  margin-bottom: 1em;
  transition: 0.5s var(--transition);
  font-size: $heading-6 !important;

  @media (max-width: 767px) {
    font-size: $body-large !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $dark !important;
  }
}
