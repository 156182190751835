/** Colors */

$prim: #5a21e8;
$prim__light: lighten($prim, 45%);
$light: #f5f5f6;
$light--2: #fff;
$dark: #100e0f;
$dark--1: lighten($dark, 50%);
$dark--2: lighten($dark, 75%);

:root {
  --transition: cubic-bezier(0.24, 0.82, 0.43, 1.05);
  --border: 2em;
  --border_mid: 1em;
  --border_low: 0.5em;
}

// new typography

$heading-1: clamp(2rem, 4.5vw, 4rem);
$heading-2: clamp(2rem, 3.5vw, 3rem);
$heading-3: clamp(1.75rem, 2.5vw, 2.25rem);
$heading-4: clamp(1.25rem, 1.8vw, 1.8rem);
$heading-5: clamp(1rem, 1.5vw, 1.5rem);
$heading-6: clamp(1rem, 1.2vw, 1.25rem);
$subheading: clamp(0.9rem, 1.1vw, 1.125rem);
$subheading-2: 0.875rem;
$body-large: clamp(0.875rem, 1vw, 1.125rem);
$body: clamp(0.875rem, 1vw, 1rem);
$caption: 0.875rem;
$caption-1: 0.7rem;
$overline: 0.6rem;

$bolder: 900;
$bold: 600;
$regular: 400;
$lighter: 200;

///// Custom inputs

@mixin hidescroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin centeritem {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Custom Links

.href {
  text-decoration: none;
  color: $dark;
  font-weight: 500;
  font-size: $body-large;
  transition: 0.5s var(--transition);

  &:hover {
    color: $prim;
    text-decoration: none !important;
  }
}

.fill--link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  transform-origin: top;
}
