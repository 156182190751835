@import "../../../App.scss";

.aboutBts {
  .container {
    overflow-x: hidden;
    width: 100%;
  }
  .bts__wrapper {
    margin-top: 2em;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(4, minmax(300px, 1fr));
    grid-template-rows: repeat(10, 1.5em);
    gap: 1em;

    @media (max-width: 1000px) {
      animation: marquee 18s linear infinite;
    }

    & .imgBlock {
      width: 100%;
      height: auto;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .img1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 11;
    }

    & .img2 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 7;
    }

    & .img3 {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 7;
      grid-row-end: 11;
    }

    & .img4 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 11;
    }

    & .img5 {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 9;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
