@import "../../App.scss";

.navbar {
  padding: 2em 0 !important;
  position: absolute !important;
  width: 100%;
  height: 100px;
  z-index: 999;

  @media (max-width: 767px) {
    padding: 1em 0 !important;
    position: relative !important;
    height: auto !important;
  }

  .navbar-brand {
    font-size: $heading-5;
    font-weight: 700;
    margin-right: 2em;
    @include centeritem;
    gap: 7px;
    & img {
      width: 32px;
      height: auto;
    }

    @media (max-width: 767px) {
      gap: 5px;
      padding: 1em 0;

      & img {
        width: 34px;
      }
    }
  }

  .navbar-toggler {
    padding: 0;
    border: none;
    .navbar-toggler-icon {
      background-image: none !important;
    }
  }

  .btnMenu02 {
    width: 44px;
    height: 44px;
    background-color: $prim__light;
    border-radius: 50%;
  }
  .btnMenu02 > span {
    display: block;
    transition: all 0.4s ease-in-out;
  }
  .btnMenu02 > span > span,
  .btnMenu02 > span::before,
  .btnMenu02 > span::after {
    display: block;
    width: 50%;
    height: 2px;
    margin: 4px auto;
    content: "";
    background-color: $prim;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
  }
  .btnMenu02.is-open > span {
    transform: rotate(180deg);
  }
  .btnMenu02.is-open > span > span {
    opacity: 0;
  }
  .btnMenu02.is-open > span::before {
    transform: translateY(6px) rotate(-45deg);
  }
  .btnMenu02.is-open > span::after {
    transform: translateY(-6px) rotate(45deg);
  }

  .navbar-nav {
    align-items: flex-start;
    transition: all 0.8s var(--transition);
    .nav-link {
      display: block;
      position: relative;
      overflow: hidden;
      padding: 0 0.75rem !important;
      margin: auto 0;

      & span {
        font-size: $body-large;
        font-weight: 600;
        color: $dark !important;
        position: relative;
        display: block;
        transition: transform 0.8s var(--transition);

        &::before {
          position: absolute;
          top: 100%;
          display: block;
          content: attr(data-hover);
          font-weight: 600;
          transform-origin: left top;
          transition: transform 0.8s var(--transition);
        }

        &:hover {
          transform: translateY(-100%);
          color: $prim !important;
        }
      }
    }

    @media (min-width: 768px) {
      .nav-link.disabled {
        display: none;
      }
    }

    & p {
      font-size: $caption-1;
      color: $dark !important;
      // padding: 0.25rem 0.75rem;

      @media (min-width: 768px) {
        display: none;
      }
    }

    @media (max-width: 767px) {
      background: $prim__light;
      border-radius: 10px;

      .nav-link {
        padding-left: 1.5em !important;
        & span {
          font-size: $heading-5;
        }
      }

      .nav-link.disabled:first-child,
      .nav-link.nav-link.disabled:last-child {
        height: 1.5em;
        display: block;
      }

      // .nav-link:first-child {
      //   margin-top: 1em !important;
      // }

      // .nav-link:last-child {
      //   margin-bottom: 1em !important;
      // }
    }
  }
}
