@import "../../../App.scss";

.aboutDocs {
  &__wrapper {
    margin-top: 2em;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;

    @media (max-width: 767px) {
      grid-template-columns: auto;
    }

    & .docsBox {
      width: 100%;
      height: auto;
      background: $prim__light;
      border-radius: var(--border_mid);
      @include centeritem;
      flex-flow: column;
      gap: 0.5em;
      padding: 4em 2em;

      & .heading-3 {
        font-weight: 700;
      }

      & .bbtn {
        margin-top: 1.5em;
      }
    }

    & .resBox {
      background: $prim;
      color: $light;

      & .bbtn {
        background: $light;
        color: $dark;

        &:hover {
          background: lighten($prim, 40%);
        }
      }
    }

    & .visBox {
      background: lighten($prim, 35%);
    }
  }
}
