/////////////////////////////////////////////////
// Mobile devices
/////////////////////////////////////////////////

@media (max-width: 767px) {
  :root {
    --border: 1.5em;
    --border_mid: 0.6em;
  }

  .container {
    padding: 0 25px !important;
  }
}

/////////////////////////////////////////////////
// tablet devices
/////////////////////////////////////////////////

@media (min-width: 768px) and (max-width: 1023px) {
}

/////////////////////////////////////////////////
// desktop screens
////////////////////////////////////////////////

@media (min-width: 1024px) {
}
