@import "../../../App.scss";

.featwork {
  .section__title {
    @media (max-width: 767px) {
      width: 50%;
    }
  }

  .projAll_btn {
    margin-top: 5vh;
  }
}
