@import "../../../App.scss";

.partner {
  position: relative;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 2em 0;
  }

  img.jp_logo {
    width: 50%;
    min-width: 300px;
    height: auto;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0.04;
  }

  .main_logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .jp__partner {
      width: 20%;
      min-width: 125px;
      z-index: 2;
    }
  }
}
