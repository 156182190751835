// Typography

.heading-1 {
  font-size: $heading-1; //64px
  font-weight: $bolder;
}

.heading-2 {
  font-size: $heading-2; //48px
  font-weight: 700;
  letter-spacing: -1px;
}

.heading-3 {
  font-size: $heading-3; //32px
}

.heading-4 {
  font-size: $heading-4; //24px
}

.heading-5 {
  font-size: $heading-5;
}

.heading-6 {
  font-size: $heading-6;
}

.subheading {
  font-size: $subheading; //18px
  letter-spacing: 0.3px;
}

.subheading-2 {
  font-size: $subheading-2; //14px
  letter-spacing: 0.3px;
}

.body-large {
  font-size: $body-large;
}

.body {
  font-size: $body;
}

.body-medium {
  font-size: $body;
  font-weight: $bold;
}

.caption {
  font-size: $caption;
  opacity: 0.8;
  color: $dark--1;
  font-weight: 400;
  line-height: 1.5;
}

.caption-1 {
  font-size: $caption-1;
  opacity: 0.8;
  color: $dark--1;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 2px;
}

.caption-medium {
  font-size: $caption;
  font-weight: $bold;
}

.caption-bold {
  font-size: $caption;
  font-weight: $bolder;
}

.overline {
  font-size: $overline;
}
