@import "../../../App.scss";

.aboutDetails {
  padding: 0;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 1em;
  background: $prim__light;

  @media (max-width: 767px) {
    grid-template-columns: auto;
  }

  &__background {
    width: 100%;
    height: 100%;

    @media (max-width: 767px) {
      display: none;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding: 10vh 2em;
    @include centeritem;
    @media (max-width: 767px) {
      padding: 4em 0;
    }

    .section__title {
      width: 40%;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    .tableWrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1em;
      margin: 5vh 0;

      & table {
        width: 75%;
        height: max-content;
        font-size: $subheading;

        @media (max-width: 767px) {
          width: 100%;
        }

        tbody tr:hover {
          background: lighten($prim, 42%);
          color: $prim;
        }

        th {
          border-top: 0;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 2px;
        }
      }
    }

    .section__subtitle {
      font-weight: 700;
      letter-spacing: 0.5px;
    }

    .exp_logo {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: row wrap;
      gap: 1em;
      margin-top: 1em;

      & img {
        width: 32px;
        height: 32px;
        object-fit: contain;
      }
    }
  }
}
